  import { useState, createContext } from "react";

  export const UserContext = createContext();

  export const UserProvider = ({children}) => {
    const [globalUserData, setGlobalUserData] = useState({
      name: "",
      email: "",
      theme: "light",
      isAuthenticated: false,
      id: "",
      username: "",
    })

    return (
      <UserContext.Provider value={{globalUserData, setGlobalUserData}}>
      {children}
      </UserContext.Provider>
    );

  }
