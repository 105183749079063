import Cookies from "js-cookie";


function setCookie(name, value, domain) {
    console.log("setting02")
    const cookieSettings = {
        expires: 30, // Specifies that the cookie will expire in 1 day
        path: "/", // Cookie is accessible for all paths on the domain
        secure: true, // Cookie is sent only over HTTPS
        sameSite: "Strict", // Less restrictive than 'Strict', but still prevents sending cookies with cross-site requests
        domain: domain // Set the domain to ensure cross-subdomain access where necessary
    };
    Cookies.set(name, value, cookieSettings);
}


function checkCookies(env) {
    let domains;
    if (env.startsWith('dev')) {
      domains = ['staging-production-jqesv.codedesign.app', 'staging-accounts.codedesign.app'];
    } else if (env.startsWith('prod')) {
      domains = ['dev.codedesign.ai', 'accounts.codedesign.ai'];
    }
  
    domains.forEach(domain => {
      // Assuming you have access to a function that can get cookies by domain, which might need server-side support
      console.log(`Cookies for ${domain}:`);
      console.log(`Token: ${Cookies.get('token', { domain })}`);
      console.log(`UserId: ${Cookies.get('userId', { domain })}`);
    });
  }

function getCookie(name) {
    let cookieArray = document.cookie.split(';');
    for (let i = 0; i < cookieArray.length; i++) {
        let cookie = cookieArray[i];
        let cookieParts = cookie.split('=');
        if (name === cookieParts[0].trim()) {
            return decodeURIComponent(cookieParts[1]);
        }
    }
    return null;
}


export {setCookie, getCookie, checkCookies};