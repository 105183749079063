import React from "react";
import { getCurrentYear } from "../../helpers/getCurrentYear";
import "./Footer.scss";

export const Footer = () => {
  return (
    <div className="footer">
      <p className="footer__description">
        © {getCurrentYear()} Codedesign Inc. Currently running in Public Beta.
        All Rights Reserved.
      </p>
    </div>
  );
};
