import React from 'react';
import { lazy, Suspense } from 'react';
import { Routes, Route } from 'react-router-dom';
import './AuthenticationContainer.scss';

const Login = lazy(() => import('../../components/Login/Login'));
const Register = lazy(() => import('../../components/Register/Register'));
const MagicLink = lazy(() => import('../../components/MagicLink/MagicLink'));
const MagicLinkValidator = lazy(() => import('../../components/MagicLinkValidator/MagicLinkValidator'));
const Logout = lazy(() => import('../../components/Logout/Logout'));


export const AuthenticationContainer = () => {
  console.log('AuthenticationContainer');
  return (
   <Suspense fallback={<Loader />}> 
    <Routes>
    <Route path="/" element={<Login />} />
    <Route path="/login" element={<Login />} />
    <Route path="/register" element={<Register />} />
    <Route path="/magic-link" element={<MagicLink />} />
    <Route path="/verify-magic-link" element={<MagicLinkValidator />} />
    <Route path="/logout" element={<Logout />} />

  </Routes>
  </Suspense>
    
  )
}


function Loader () {
  return(
    <div className='loader__container'>
      <div className='loader__content'></div>
    </div>
  )
}