import React from "react";
import { environmentURLs } from "../../utils/environmentURLs";
import { useLocation, useNavigate } from "react-router";
import "./Header.scss";

const { frontendUrl } = environmentURLs;

export const Header = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const redirect = location.state?.redirect || "/a/dashboard";
  const pathname = location.pathname;

  return (
    <div 
    id="header"
    className="header">
      <a href={frontendUrl} className="header__logo-link">
        <img src={environmentURLs.codedesignIconURL} alt="codedesign.ai logo" />
      </a>

      {pathname === "/register" ? (
        <button
          onClick={() =>
            navigate("/login", {
              state: { redirect: redirect, requestedFrom: "header" },
            })
          }
          className="btn__login"
        >
          <span className="btn__text">Login</span>
        </button>
      ) : (
        <button
          onClick={() =>
            navigate("/register", {
              state: { redirect: redirect, requestedFrom: "header" },
            })
          }
          className="btn__register"
        >
          <span className="btn__text">Register</span>
        </button>
      )}
    </div>
  );
};
